<template>
  <div class="plainformbox">
    <div class="counter">
      <button class="js-del-row btn-count remove" v-if="index > 0" @click="remove">
        X
        <!-- <i class="far fa-window-close"></i> -->
      </button>
      <div :class="['form-group', 'd-flex', 'justify-content-between', 'align-items-center', index > 0 ? 'mt-4' : '']">
        <label>{{ $t('product-page.room-type') }}</label>
        <b-form-select v-model="currentType" ref="roomType" :options="optionByRoomTypes" :disabled="staticRoomTypes || priceLoading" :class="[staticRoomTypes ? 'static': '']"></b-form-select>
      </div>
      <div class="form-group d-flex justify-content-between align-items-center">
        <label>{{ $t('product-page.room-class') }}</label>
        <b-form-select v-model="currentClassCode" :options="optionByRoomClasses" v-if="!isOrganizeTour" :disabled="staticRoomClass || priceLoading" :class="[staticRoomClass ? 'static': '']"></b-form-select>
        <input v-else class="form-control room-option text-center" type="text" :value="getRoomClassName(currentClassCode)" readonly/>
      </div>
      <div class="form-group d-flex justify-content-between align-items-center">
        <label>{{ $t('product-page.basis') }}</label>
        <b-form-select v-model="currentBasis" :options="optionsBasis" v-if="!isOrganizeTour" :disabled="staticRoomBasis || priceLoading" :class="[staticRoomBasis ? 'static': '']"></b-form-select>
        <input v-else class="form-control room-option text-center" type="text" :value="getRoomBasisName(currentBasis)" readonly/>
      </div>
      <p>{{ $t('product-page.babies') }}</p>
      <input type="text" class="form-control" :value="countOfBaby" />
      <button class="btn-count" @click="addBabies" :disabled="priceLoading">+</button>
      <button class="btn-count" @click="subtractBabies" :disabled="priceLoading">-</button>
    </div>
    <p>{{ $t('product-page.total-per-passenger') }}</p>
    <h4>{{ getPriceWithSymbol( product.cc, averagePrice) }}</h4>
    <p class="pt-3" v-if="showRateRoom">{{ totalPriceRoom }}</p>
  </div>
</template>

<script>
import { BFormSelect } from 'bootstrap-vue';
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import gMixin from '@/utils/mixins';
import imageUrlMixin from '@/utils/imageUrlMixin';

const { VUE_APP_CHANNEL_MOBILE_APP, VUE_APP_MAX_DISCOUNT } = process.env;

export default {
  mixins: [gMixin, imageUrlMixin],
  components: {
    BFormSelect,
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    data: {
      type: Object,
      default: null,
    },
    hotels: {
      type: Array,
      default: () => [],
    },
    showRateRoom: {
      type: Boolean,
      default: false,
    },
    translations: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      category: 'GET_CURRENT_CATEGORY',
      product: 'GET_PRODUCT',
      roomClassList: 'GET_ROOM_CLASS',
      roomTypeList: 'GET_ROOM_TYPE',
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
      typeChannel: 'GET_TYPE_CHANNEL',
      flights: 'GET_FLIGHT_ID',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
      bypassPaymentState: 'GET_BYPASS_PAYMENT_STATE',
    }),
    staticRoomTypes() {
      return this.optionByRoomTypes.length < 2;
    },
    staticRoomClass() {
      return this.optionByRoomClasses.length < 2;
    },
    staticRoomBasis() {
      return this.optionsBasis.length < 2;
    },
    totalPriceRoom() {
      let strPrice = this.getPriceWithSymbol(this.product.cc, this.priceAdult * this.ctAdults + this.priceChild * this.ctChildren);
      if (this.countOfBaby > 0) {
        strPrice += ` + ${this.getPriceWithSymbol(this.product.cc, 120 * this.countOfBaby)} ( ${this.countOfBaby === 1 ? this.$t('product-page.baby') : this.$t('product-page.babies')} )`;
      }
      strPrice = `${this.$t('product-page.total')} ${strPrice}`;
      return strPrice;
    },
  },
  data() {
    return {
      isOrganizeTour: '',
      personString: '',
      optionByRoomTypes: [],
      optionByRoomClasses: [],
      basisPackage: [],
      optionsBasis: [],
      conjugationBasis: [],
      currentClassCode: '',
      currentType: { code: '', kind: '' },
      currentTypeCode: '',
      currentBasis: '',
      currentBasisText: '',
      countOfBaby: 0,
      babies: [
        { value: 0, text: this.$t('product-page.noBabies') },
        { value: 1, text: 1 },
        { value: 2, text: 2 },
        { value: 3, text: 3 },
      ],
      currentPrice: 0,
      ctAdults: 0,
      priceAdult: 0,
      ctChildren: 0,
      priceChild: 0,
      ctInfants: 0,
      averagePrice: 0,
      roomClassName: '',
      totalPrice: 0,
      oldPrice: 0,
      oldTotal: 0,
      priceLoading: false,
      isMobileApp: false,
    };
  },
  watch: {
    data: {
      handler() {
        if (this.data.roomBasis !== this.currentBasis) {
          this.currentBasis = this.data.roomBasis;
          this.updateItem();
        }
      },
      deep: true,
    },
    currentClassCode(newValue, oldValue) {
      if (newValue === oldValue) return;
      // this.getRoomBasisOptions();
      this.updateItem();
      this.roomClassName = this.getRoomClassName(this.currentClassCode);
    },
    currentType(newValue, oldValue) {
      if (newValue === oldValue) return;
      this.currentTypeCode = this.currentType.code;
      this.getRoomClassOptions(this.currentTypeCode === this.$route.query.roomType);
      this.updateItem();
    },
    currentBasis(newValue, oldValue) {
      if (newValue === oldValue) return;
      this.updateItem();
    },
    countOfBaby(newValue, oldValue) {
      if (newValue === oldValue) return;
      this.updateItem();
    },
    priceLoading() {
      this.$emit('setPriceLoading', this.priceLoading);
    },
    flights() {
      this.updateItem();
    },
    lang() {
      this.updateItem();
      this.getRoomTypeOptions();
      // this.getRoomClassOptions('lang');
      this.getRoomBasisOptions();
      this.babies = [
        { value: 0, text: this.$t('product-page.noBabies') },
        { value: 1, text: 1 },
        { value: 2, text: 2 },
        { value: 3, text: 3 },
      ];
      this.roomClassName = this.getRoomClassName(this.currentClassCode);
    },
  },
  async created() {
    this.isOrganizeTour = this.category.code === 'Organize_tour_packages';

    // this.basisPackage = await this.$store.dispatch('FETCH_ROOM_BASIS_CONJUGATE', this.$route.query.packId);
    this.basisPackage = this.product.conjugatePackages;
    this.getRoomBasisOptions();
    if (this.data.roomBasis !== '') this.currentBasis = this.data.roomBasis;
    this.isMobileApp = this.typeChannel === VUE_APP_CHANNEL_MOBILE_APP;
  },
  beforeMount() {
    this.currentTypeCode = this.$route.query.roomType;
    this.getRoomTypeOptions();
    this.countOfBaby = Number(this.$route.query.infant);
  },
  methods: {
    async getRoomBasisOptions() {
      if (this.basisPackage.length === 0) return;
      const basis = [...new Set(this.basisPackage.map((item) => ({
        value: item.basis,
        text: item.basisTranslations[this.lang],
        packId: item.packId,
      })))];
      this.optionsBasis = basis;
      if (this.optionsBasis.length === 0) {
        this.$bvModal
          .msgBoxOk(this.$t('product-page.expire-message'), {
            title: this.$t('product-page.expire-title'),
            okVariant: 'danger',
            okTitle: this.$t('product-page.go-back'),
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true,
          })
          .then(() => {
            // this.$router.push('/');
          })
          .catch(() => {
            // An error occurred
          });
      }
      const currBasis = basis.find((item) => item.packId === Number(this.$route.query.packId));
      this.currentBasis = currBasis?.value || basis[0].value;
    },
    getRoomTypeOptions() {
      const types = [...new Set(this.hotels.map((item) => item.Room_Type))];
      // this.optionByRoomTypes = types.map((item) => ({
      //   value: item,
      //   text: this.getRoomTypeName(item),
      // }));
      const onlyAdult = this.hotels[0].desc.includes('מבוגרים בלבד');
      this.optionByRoomTypes = [];
      types.forEach((item) => {
        const { adult, child } = this.getOccupancy(item);
        if (item === 'TR3') {
          if (this.currentTypeCode === item) {
            this.currentType = { code: item, kind: (Number(this.$route.query.adult) === 2 && Number(this.$route.query.child) === 1) ? 'TR3-2adu-1chd' : 'TR3-3adu' };
          }
          this.optionByRoomTypes.push({
            value: { code: item, kind: 'TR3-3adu' },
            text: this.lang === 'he' ? '3 מבוגרים' : '3adults',
            occupancy: adult + child,
          });
          if (!onlyAdult) {
            this.optionByRoomTypes.push({
              value: { code: item, kind: 'TR3-2adu-1chd' },
              text: this.lang === 'he' ? '2 מבוגרים וילד' : '2adults + 1child',
              occupancy: adult + child,
            });
          }
        } else {
          if (this.currentTypeCode === item) {
            this.currentType = { code: item, kind: item };
          }
          this.optionByRoomTypes.push({
            value: { code: item, kind: item },
            text: this.getRoomTypeName(item),
            occupancy: adult + child,
          });
        }
      });
      this.optionByRoomTypes.sort((a, b) => a.occupancy - b.occupancy);
      if (this.optionByRoomTypes.length === 0) {
        this.$bvModal
          .msgBoxOk(this.$t('product-page.expire-message'), {
            title: this.$t('product-page.expire-title'),
            okVariant: 'danger',
            okTitle: this.$t('product-page.go-back'),
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true,
          })
          .then(() => {
            // this.$router.push('/');
          })
          .catch(() => {
            // An error occurred
          });
      }
    },
    getRoomClassOptions(isMounted) {
      const classes = [...new Set(this.hotels.filter((item) => item.Room_Type === this.currentTypeCode).map((item) => item.Room_Class))];
      const clsStd = this.$route.query.roomClass;
      const stdIdx = classes.indexOf(clsStd);
      if (stdIdx !== -1) {
        classes.splice(stdIdx, 1);
        classes.unshift(clsStd);
      }
      this.optionByRoomClasses = classes.map((item) => ({
        value: item,
        text: this.getRoomClassName(item),
      }));
      if (this.optionByRoomClasses.length === 0) {
        this.$bvModal
          .msgBoxOk(this.$t('product-page.expire-message'), {
            title: this.$t('product-page.expire-title'),
            okVariant: 'danger',
            okTitle: this.$t('product-page.go-back'),
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true,
          })
          .then(() => {
            // this.$router.push('/');
          })
          .catch(() => {
            // An error occurred
          });
      }
      if (isMounted !== 'lang') {
        const existingClass = classes.indexOf(this.$route.query.roomClass);
        if (existingClass !== -1) {
          this.currentClassCode = this.$route.query.roomClass;
        // } else {
        //   this.currentClassCode = '';
        // }
        } else {
          this.currentClassCode = classes[0];
        }
      }
    },
    getRoomClassName(roomClassCode) {
      const ts = this.translations?.Room_Class?.[roomClassCode]?.[this.lang] || this.translations?.Room_Class?.[roomClassCode]?.en;
      const classHotel = this.hotels.find((hotel) => (hotel.Room_Class === roomClassCode));
      return (this.isOdysseySite) ? `${ts || roomClassCode} ${classHotel.status}-${classHotel.free}` : ts || roomClassCode;
    },
    getRoomTypeName(roomTypeCode) {
      const ts = this.translations?.Room_Type?.[roomTypeCode]?.[this.lang] || '';
      return ts || roomTypeCode;
    },
    getRoomBasisName(roomBasis) {
      const basis = this.optionsBasis.find((item) => item.value === roomBasis);
      return basis.text;
    },
    getPersonString(adult, child, infant) {
      let str = '';
      if (adult === 1) {
        str = `${adult} ${this.$t('product-page.adult')}`;
      } else {
        str = `${adult} ${this.$t('product-page.adults')}`;
      }
      if (child === 1) {
        str += ` + ${child} ${this.$t('product-page.child')}`;
      } else if (child > 1) {
        str += ` + ${child} ${this.$t('product-page.children')}`;
      }
      if (infant === 1) {
        str += ` + ${infant}  ${this.$t('product-page.infant')}`;
      } else if (infant > 1) {
        str += ` + ${infant}  ${this.$t('product-page.infants')}`;
      }
      return str;
    },
    remove() {
      this.$emit('closed', this.index);
    },
    availableDiscount(pPriceData) {
      let available = true;
      // if ((pPriceData.price_average / pPriceData.originalPrice.price_average) * 100 < (100 - VUE_APP_MAX_DISCOUNT)) available = false;
      if (pPriceData.discountPercent > VUE_APP_MAX_DISCOUNT) available = false;
      available = true;
      return available;
    },
    async updateItem() {
      if (this.priceLoading) return;
      let roomtype = this.currentTypeCode,
        { adult, child } = this.$route.query;
      const { packId, laId, dateFrom } = this.$route.query;

      const roomOption = this.optionByRoomTypes.filter(
        (opt) => opt.value.code === roomtype,
      )[0];

      const dataRoomType = this.roomTypeList.find((item) => item.code === this.currentTypeCode);

      if (roomOption && this.currentClassCode !== '' && dataRoomType) {
        // const { child: decideChild, adult: decideAdult, roomType: decideRoomType } = this.getOccupancy(roomtype);
        // if (decideRoomType === 'DB' || decideRoomType === 'TR3') {
        //   adult = decideAdult;
        //   child = decideChild;
        //   roomtype = decideRoomType;
        // }
        if (dataRoomType.code === 'DB') {
          adult = 2;
          child = 0;
        } else if (dataRoomType) {
          adult = dataRoomType.adultsMin;
          child = dataRoomType.maxPaX - dataRoomType.adultsMin;
        }
        if (this.currentTypeCode === 'TR3') {
          switch (this.currentType.kind) {
            case 'TR3-3adu':
              adult = 3; child = 0;
              break;
            case 'TR3-2adu-1chd':
              adult = 2; child = 1;
              break;
            default:
              adult = 3; child = 0;
          }
          roomtype = 'TR3';
        }

        const currBasis = this.currentBasis !== '' ? this.optionsBasis.find((item) => item.value === this.currentBasis) : this.optionsBasis.find((item) => item.packId === Number(packId));

        const body = {
          packId: currBasis ? currBasis.packId : packId,
          hotelid: laId,
          packSelectionId: this.data.packSelectionId,
          cls: this.currentClassCode,
          typ: roomtype,
          dateArv: dayjs(dateFrom).format('DD/MM/YYYY'),
          infant: this.countOfBaby,
          flights: this.flights,
          adult,
          child,
        };
        this.priceLoading = true;
        const priceData = await this.$store.dispatch(
          'FETCH_PRICES_BY_CLASS_TYPE',
          body,
        );

        let updateData = null;
        if (priceData === 'error') {
          this.currentPrice = 0;
          // updateData = {
          //   index: this.index,
          //   hotelId: '',
          //   packSelectionId: this.data.packSelectionId,
          //   roomClass: null,
          //   roomType: null,
          //   roomBasis: '',
          //   roomBasisCode: '',
          //   price: 0,
          //   adult: 0,
          //   child: 0,
          //   infant: 0,
          //   totalPrice: 0,
          //   oldPrice: 0,
          //   oldTotal: 0,
          // };
          this.priceLoading = false;
          this.totalPrice = 0;
          this.$emit('update', {
            success: false,
            message: {
              kind: 'error-fetching-price',
              message: this.$t('product-page.error-fetching-price'),
            },
          });
        // } else if (!this.availableDiscount(priceData)) {
        //   this.priceLoading = false;
        //   this.totalPrice = 0;
        //   this.$emit('update', {
        //     success: false,
        //     message: {
        //       kind: 'error-discount',
        //       message: this.$t('product-page.error-discount'),
        //     },
        //   });
        } else if (priceData.babyDisallowed && this.countOfBaby > 0) {
          this.countOfBaby = 0;
          const title = this.$t('product-page.title-warning-baby'),
            message = this.$t('product-page.message-warning-baby');
          this.$bvToast.toast(
            message,
            {
              title,
              autoHideDelay: 5000,
              appendToast: true,
              variant: 'warning',
              toaster: 'b-toaster-top-right',
              noClosebutton: true,
              bodyClass: this.lang === 'he' ? 'rtl' : 'ltr',
            },
          );
          this.priceLoading = false;
          this.totalPrice = 0;
          this.$emit('update', {
            success: false,
            message: {
              kind: 'error-disable-baby',
              message: this.$t('product-page.error-disable-baby'),
            },
          });
        } else {
          if (priceData.price_average > 9000) {
            this.$bvModal
              .msgBoxOk(this.$t('product-page.updating-now-message'), {
                title: this.$t('product-page.expire-title'),
                okVariant: 'danger',
                okTitle: this.$t('product-page.go-back'),
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0',
                centered: true,
              })
              .then(() => {
                // this.$router.push('/');
              })
              .catch(() => {
                // An error occurred
              });
            priceData.price_average = 0;
            priceData.price = 0;
          }
          this.currentPrice = priceData.priceAdl;
          this.ctAdults = priceData.adults;
          this.priceAdult = priceData.priceAdl;
          this.ctChildren = priceData.child;
          this.priceChild = priceData.priceChd;
          this.ctInfants = priceData.infant;
          if (priceData.price_average > 0) {
            this.averagePrice = priceData.price_average;
            updateData = {
              success: true,
              index: this.index,
              hotelId: laId,
              packSelectionId: this.data.packSelectionId,
              roomClass: {
                text: this.roomClassName,
                value: this.currentClassCode,
              },
              roomType: {
                text: roomOption?.text,
                value: roomOption?.value?.code,
              },
              roomBasis: this.currentBasis,
              roomBasisCode: currBasis,
              price: priceData.price_average,
              adult: priceData.adults,
              child: priceData.child,
              infant: this.countOfBaby,
              priceInf: priceData.priceInf,
              totalPrice: priceData.price, // TODO: should sum infant value
              oldPrice: priceData.originalPrice
                ? priceData.originalPrice.price_average
                : (Number(priceData.price_average) * 1.1).toFixed(0),
              oldTotal: priceData.originalPrice
                ? priceData.originalPrice.price
                : (Number(priceData.price) * 1.1).toFixed(0),
              cc: this.data.cc,
            };
            this.totalPrice = priceData.price;
            this.oldPrice = updateData.oldPrice;
            this.oldTotal = updateData.oldTotal;
            this.personString = this.getPersonString(
              updateData.adult,
              updateData.child,
              updateData.infant,
            );
            this.priceLoading = false;
            this.$emit('update', updateData);
          } else {
            this.priceLoading = false;
            this.totalPrice = 0;
            this.$emit('update', {
              success: false,
              message: {
                kind: 'error-room-type',
                message: this.$t('product-page.error-room-type'),
              },
            });
          }
        }
      } else {
        this.priceLoading = false;
        this.totalPrice = 0;
        this.$emit('update', {
          success: false,
          message: {
            kind: 'error-room-type',
            message: this.$t('product-page.error-room-type'),
          },
        });
      }
    },
    getOccupancy(pRoomType) {
      let adult = 0, child = 0, roomType = pRoomType;

      const dataRoomType = this.roomTypeList.find((item) => item.code === pRoomType);

      if (dataRoomType.code === 'DB') {
        adult = 2;
        child = 0;
      } else if (dataRoomType) {
        adult = dataRoomType.adultsMin;
        child = dataRoomType.maxPaX - dataRoomType.adultsMin;
      }
      if (pRoomType === 'TR3') {
        switch (this.currentType.kind) {
          case 'TR3-3adu':
            adult = 3; child = 0;
            break;
          case 'TR3-2adu-1chd':
            adult = 2; child = 1;
            break;
          default:
            adult = 3; child = 0;
        }
        roomType = 'TR3';
      }
      return { adult, child, roomType };
    },
    addBabies() {
      this.countOfBaby += 1;
      if (this.countOfBaby > 3) this.countOfBaby = 3;
    },
    subtractBabies() {
      this.countOfBaby -= 1;
      if (this.countOfBaby < 0) this.countOfBaby = 0;
    },
  },
};
</script>
<style>
  .form-group select.static {
    -webkit-appearance: none !important;
    appearance: none !important;
  }
</style>
<style scoped>
s {
  opacity: 0.5;
}
.ptag {
  text-align: center;
}
.p-price {
  display: inline-flex;
  align-items: baseline;
}
.table > thead > tr > th {
  padding: 12px 3px;
}
.table td {
  padding: 0.75rem 0.1rem;
  vertical-align: middle;
  text-align: center;
}
.custom-select {
  padding: 0.375rem 1.55rem 0.375rem 0.05rem;
}
@media (max-width: 479px) {
  .card-body {
    padding: 0.25rem;
  }
  .flight-details-leftbox .table > tbody > tr > td,
  .flight-details-leftbox .table > tbody > tr > th,
  .flight-details-leftbox .table > tfoot > tr > td,
  .flight-details-leftbox .table > tfoot > tr > th,
  .flight-details-leftbox .table > thead > tr > td,
  .flight-details-leftbox .table > thead > tr > th {
    padding: 4px;
  }
  .counter .remove {
    margin-top: 10px;
  }
}
.form-check-input {
  right: -15px;
}
.form-control[readonly] {
  background: #FFF !important;
}
.room-option {
  font-size: 16px;
}
.plainformbox input.room-option {
  width: 100%;
  max-width:60%;
}
</style>
